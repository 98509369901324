'use client';

import cn from 'classnames';

import styles from '../styles/FooterLogin.module.scss';

import { Link } from '@lib/navigation';
import { logout } from '@utils/api/client';
import { useUserStore } from '@utils/user';
import PersonSVG from 'public/static/svg/person-background.svg';
export default function FooterLogin({
  labels,
  menuClose,
}: {
  labels: { logIn: string; logOut: string };
  menuClose: () => void;
}) {
  const userStore = useUserStore();

  const buttonApperance =
    'flex gap-x-2 items-center justify-center bg-white text-premium-dark hover:text-premium-900 font-bold rounded-3xl py-1 pl-1 pr-4';

  const svgApperance = cn(
    'flex h-8 w-8 items-center justify-center',
    styles.svg,
  );

  if (!userStore.isLoggedIn) {
    return (
      <Link className={buttonApperance} href="/login" rel="nofollow" onClick={menuClose}>
        <span className={svgApperance}>
          <PersonSVG />
        </span>
        {labels.logIn}
      </Link>
    );
  }
  return (
    <button
      className={buttonApperance}
      onClick={async (e) => {
        e.preventDefault();
        await logout();
      }}
    >
      <span className={svgApperance}>
        <PersonSVG />
      </span>
      {labels.logOut}
    </button>
  );
}
