'use client';
import sample from 'lodash/sample';
import { useLocale } from 'next-intl';
import { useEffect, useMemo } from 'react';

import { Link } from '@lib/navigation';
import { useUserStore } from '@utils/user';

const mergedLength = (texts: string[]) => {
  return texts.reduce((acc, text) => acc + text.length + 4, 0);

}

export default function InfoBarClient({ infoBars }) {
  const locale = useLocale();
  const userStore = useUserStore();

  const data = useMemo(() => {
    if (!userStore.isReady) {
      return null;
    }

    const allowed = infoBars.filter(
      (item) =>
        item.lang === locale &&
        (!item.hideForPremiumUsers || !userStore.isPremium),
    );

    if (allowed.length > 0) {
      return sample(allowed);
    }

    return null;
  }, [infoBars, locale, userStore.isPremium, userStore.isReady]);

  useEffect(() => {
    if (data) {
      userStore.setIsInfoBarVisible(true);
    } else {
      userStore.setIsInfoBarVisible(false);
    }
  }, [data]);

  if (!userStore.isInfoBarVisible || !data) {
    return <></>;
  }

  const { url, openInNewTab, text, textColor, bgColor } = data;

  const style = {
    color: textColor || '#000',
    background: bgColor || '#fff',
  };

  // Duplicate the text content for looping effect
  const duplicatedText = [...text, ...text];
  while (mergedLength(duplicatedText) < 500) {
    duplicatedText.push(...text, ...text);
  }

  return (
    <div className='relative overflow-hidden w-full h-[40px]'>
      <Link
        id="infoBar"
        href={url}
        target={openInNewTab ? '_blank' : undefined}
        rel="noopener noreferrer"
        className="group absolute w-full h-[40px] left-0 block items-center top-0 bg-black py-2 text-sm font-semibold text-white"
        style={style}
      >
        <span className="group-hover:pause inline-flex w-max animate-infinite-scroll-horizontal items-center">
          {duplicatedText.map((item, index) => (
            <span
              className="flex flex-shrink-0 flex-grow items-center pl-2 pr-2 border-l border-gray-500"
              key={`infobar-text-${index}`}
              style={style}
            >
              {item}
            </span>
          ))}
        </span>
      </Link>
    </div>
  );
}
