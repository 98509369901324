'use client';
import cn from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useWindowScroll } from 'react-use';

const HEADER_TOGGLE_SCROLL_OFFSET = 30;

interface DynamicHeaderWrapperProps {
  children: ReactNode;
}

export default function DynamicHeaderWrapper({
  children,
}: DynamicHeaderWrapperProps) {
  const { y } = useWindowScroll();
  const prevScroll = useRef(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (y === 0) {
      setVisible(true);
      prevScroll.current = y;
      return;
    }

    const diff = prevScroll.current - y;

    if (Math.abs(diff) < HEADER_TOGGLE_SCROLL_OFFSET) {
      prevScroll.current = y;
      return;
    }

    setVisible(diff > 0);
    prevScroll.current = y;
  }, [y]);

  return (
    <>
      <div
        className={cn('w-full top-0 transition-all', {
          '-translate-y-full': !visible,
        })}
      >
        {children}
      </div>
    </>
  );
}
